var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.error
    ? _c(
        "div",
        {
          staticClass:
            "inset-0 flex items-center justify-center fixed bg-black-70 z-a6"
        },
        [
          _c("div", { staticClass: "p-8 bg-white shadow-card" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.message) + "\n\t\t"),
            _c("div", { staticClass: "mt-3" }, [
              _c(
                "button",
                {
                  staticClass: "button bg-red text-white",
                  on: { click: _vm.dismiss }
                },
                [_vm._v("Dismiss")]
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }