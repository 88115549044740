var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: "finish-step",
      staticClass: "flex items-stretch transition-opacity min-h-xl",
      class: {
        "opacity-0": !_vm.visible
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col items-start justify-center order-1 p-6 md:order-0 md:p-8 lg:p-12 md:w-c6"
        },
        [
          _c(
            "div",
            {
              staticClass: "mt-0 mb-auto font-bold text-14 text-theme-highlight"
            },
            [_vm._v("\n      One more thing...\n    ")]
          ),
          _vm._v(" "),
          !_vm.complete
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-4 font-light max-w-108 text-16 leading-medium"
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "mb-8 font-light leading-tight text-28" },
                      [_vm._v(_vm._s(_vm.customMessage))]
                    ),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        staticClass: "w-full",
                        attrs: { action: "/" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submitDemographics($event)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex-1 ml-auto max-w-50 md:max-w-64"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.fullName,
                                    expression: "fullName"
                                  }
                                ],
                                staticClass:
                                  "flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16",
                                attrs: {
                                  type: "text",
                                  required: "",
                                  maxlength: _vm.maxLength
                                },
                                domProps: { value: _vm.fullName },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.fullName = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-center mt-2" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex-1 ml-auto max-w-50 md:max-w-64"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.address,
                                    expression: "address"
                                  }
                                ],
                                staticClass:
                                  "flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16",
                                attrs: {
                                  type: "text",
                                  required: "",
                                  maxlength: _vm.maxLength
                                },
                                domProps: { value: _vm.address },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.address = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-center mt-2" }, [
                          _vm._m(2),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex-1 ml-auto max-w-50 md:max-w-64"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.postcode,
                                    expression: "postcode"
                                  }
                                ],
                                staticClass:
                                  "flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16",
                                attrs: {
                                  placeholder: "e.g. N1",
                                  type: "text",
                                  required: "",
                                  maxlength: _vm.maxPostcode
                                },
                                domProps: { value: _vm.postcode },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.postcode = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-center mt-2" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mr-4 font-normal whitespace-no-wrap min-w-30 text-16"
                            },
                            [_vm._v("\n              Email:\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex-1 ml-auto max-w-50 md:max-w-64"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email"
                                  }
                                ],
                                staticClass:
                                  "flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16",
                                attrs: {
                                  placeholder: "me@example.com",
                                  type: "email",
                                  maxlength: _vm.maxLength
                                },
                                domProps: { value: _vm.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.email = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "mt-4 font-light text-12 leading-medium rte",
                          domProps: { innerHTML: _vm._s(_vm.smallPrint) }
                        })
                      ]
                    )
                  ]
                )
              ]
            : [
                _c(
                  "div",
                  { staticClass: "w-full my-auto max-w-94" },
                  [
                    _c("svg-icon", {
                      staticClass: "mt-auto mb-2 party-hat",
                      attrs: { icon: "party" }
                    }),
                    _vm._v(" "),
                    _c("h2", { staticClass: "mb-2 font-medium text-28" }, [
                      _vm._v("You're all done!")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-2 font-light leading-tight text-28" },
                      [_vm._v("Thanks for completing the poll.")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "inline-flex items-center h-10 px-6 mt-4 ml-auto font-serif antialiased italic font-normal bg-white border rounded-full cursor-pointer text-14 text-theme-highlight border-theme-highlight hover:bg-theme-highlight hover:text-white",
                        attrs: { id: "card-close-button", to: "/" }
                      },
                      [_vm._v("Close")]
                    )
                  ],
                  1
                )
              ],
          _vm._v(" "),
          _c("div", { staticClass: "h-px mt-auto mb-0" })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative self-stretch md:order-1 order-0 md:w-c6" },
        [
          _c("img", {
            staticClass:
              "relative z-0 hidden object-cover object-center w-full h-full md:block",
            attrs: { src: _vm.image }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "mr-4 font-normal whitespace-no-wrap min-w-30 text-16" },
      [
        _vm._v("\n              Full Name:"),
        _c("span", { staticClass: "text-red-90" }, [_vm._v("*")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "mr-4 font-normal whitespace-no-wrap min-w-30 text-16" },
      [
        _vm._v("\n              House Number:"),
        _c("span", { staticClass: "text-red-90" }, [_vm._v("*")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "mr-4 font-normal whitespace-no-wrap min-w-30 text-16" },
      [
        _vm._v("\n              Postcode:"),
        _c("span", { staticClass: "text-red-90" }, [_vm._v("*")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex justify-end w-full mt-2" }, [
      _c(
        "button",
        {
          staticClass:
            "h-10 px-6 mt-4 font-serif antialiased italic font-normal text-white rounded-full cursor-pointer bg-theme-highlight text-14",
          attrs: { type: "submit" }
        },
        [_vm._v("Submit")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }