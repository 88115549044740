

/**
 * 
 * Build vue-router config from a directory of vue components.
 * This just mimics what Nuxt does out of the box, it's a useful
 * convention as it makes `/src/js/components/pages` behave
 * a bit like `templates` in Craft.
 * 
 * The convention is that underscored implement a route params system,
 * so for eexample:
 * 
 * page/_slug/index could match route like page/cocktails/ and then
 * "cocktails" would be assigned to "slug" in the route params.
 * 
 * Vue-router doesn't use this syntax, they use routes like page/:slug/index
 * but we can't name our files that way.
 * 
 * @param {String} fileName 
 * @param {Object} componentConfig 
 */

export const routeBuilder = function (fileName, componentConfig = {}) {

	// filename will be something like `subdirectory/_page/index.vue`

	// parse the filename...
	let pathString = fileName
		// 1. Get rid of './' from the beginning
		.replace(/^\.\//, '')

		// 2. get rid of the file extension
		.replace(/\.vue$/, '')
		
		// 3. if the file ends 'index' then, it's just the root, so
		// remove 'index' from the string.
		.replace(/\/?index$/, '');

	// pathString should now be something like:
	// 'subdirectory/_page/'

	// Components might have been loaded in different ways,
	// and either be the object, or the 'default' export of
	// a component, so normalise this...
	const c = componentConfig.default || componentConfig;

	// We can pass a custom 'layout' property in our components,
	// this is non standard but it's again, a nice nuxt convention, 
	// where we choose a certain layout - good for defining the
	// 'chrome' of a page, for example, the overlay state.
	const layout = c.layout || 'default';


	// Check if the path starts with an underscore...
	const regexp = /\/?_/g;
	let path;
	let patternMatch = pathString.match(regexp);

	// If it did...
	if (patternMatch) {

		// Then find some matches:
		const replaced = pathString.replace(regexp, (match, a, i) => {

			// if it does, convert to the colon syntax for vue-router

			// if it contained with a slash, return it like so,
			if (match.substring(0, 1) === '/') {
				return '/:';
			}
			
			// or just the colon
			return ':';
		});

		// then return this path with a forward slash
		path = `/${replaced}`;
	} else {

		// if no underscore match just return as is...
		path = `/${pathString}`;
	}

	// give the component a name...
	let letters = pathString.split('')
	let name = ``;
	
	// Provide a name to the component, replacing slashes and underscores
	// and PascalCasing.
	let wasLetter = true
	let first = true
	letters.forEach( l => {

		if ( l !== '_' && l !== '/' ) {
			if ( !wasLetter || first ) {
				name = `${name}${l.toUpperCase()}`
			} else {
				name = `${name}${l}`
			}
			wasLetter = true
		} else {
			wasLetter = false
		}

		first = false
	})

  // ensure we always finish with a single trailing slash
  if(path.slice(-1) != '/') {
    path.concat('/');
  }

	c.name = c.name || `Pages${name ? name : 'Index'}`;
	// return the prepared route. This is standard vue-router config
	// where it takes...
	return {

		// the route to match to:
		path,
		// our custom layout value
		layout,
		// also passed to meta data
		meta: {
			layout,
		},
		
		// and the exported vue component itself. This does mean that all our routes are
		// NOT lazyloaded. If that is important we may have to take a different approach, 
		// but each page would in theory be quite lightweight and you could lazy load the components
		// inside.
		component: c
	}
}

export default { routeBuilder };