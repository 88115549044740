<template>
  <div>
    <div class="md:flex items-start mt-8">
      <div class="md:w-c5 mt-8">
        <div class="w-full">
          <base-slide-title class="min-h-24">
            <transition name="fade-simple" mode="out-in">
              <div key="showanswers-true" v-if="showAnswers && matchingPercent">
                Great!
                <span class="text-theme-highlight">{{ matchingPercent }}</span> of your community agree with you!
              </div>
              <div key="showanswers-true-noanswer" v-else-if="showAnswers">Thanks for answering!</div>
              <div key="showanswers-false" v-else>{{ question.text }}</div>
            </transition>
          </base-slide-title>

          <AgreeSlider class :locked="showAnswers || locked" :min="0" :max="10" 
            v-model="slider" />

          <transition name="fade-up-delay">
            <div class="w-full h-8" v-if="showAnswers && averageVote >= 0">
              <div
                class="average-vote-marker"
                :style="{
                marginLeft: `calc( ${averageVote * 10}% - ${( averageVote - 5) * 3 }px )`
              }"
              >
                <svg-icon
                  icon="indicator-arrow"
                  size="4"
                  class="absolute block -ml-2 left-0 top-0 fill-current text-theme-highlight"
                />
                <div
                  :class="{
                  'text-center': averageVote > 1 && averageVote < 9,
                  'text-left left-0': averageVote <= 1,
                  'text-right right-0': averageVote >= 9,
                }"
                  class="average-vote-text"
                >Average Vote</div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="md:mt-0 mt-10 md:w-c7 md:pl-8">
        <div v-if="question.image">
          <img :src="question.image.url" :alt="question.image.title" class="w-full h-auto block" />
        </div>
      </div>
    </div>
    <div class="text-right mt-8">
      <SaveButton
        @submit="submit"
        :loading="$store.state.question.loading"
        :complete="showAnswers"
        :next-url="nextUrl"
      >
        <template v-if="status">{{ status }}</template>
      </SaveButton>
    </div>
  </div>
</template>

<script>
import AgreeSlider from '@/vue/components/inputs/AgreeSlider.vue'
import SaveButton from '@/vue/components/inputs/SaveButton.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    question: {
      type: Object,
    },
    nextUrl: {
      type: [String, Boolean],
      required: false,
    },
  },
  components: {
    AgreeSlider,
    SaveButton,
  },
  computed: {
    ...mapGetters('question', [
      'hasAnswered',
    ]),
    averageVote() {
      const { answers } = this.question
      if (answers) {
        const { total } = answers
        return total.average || false
      }
      return false
    },
    matchingPercent() {
      const { answers } = this.question
      if (answers) {
        const { total } = answers
        if (total.same) {
          return `${Math.round(total.same * 100)}%`
        }
      }

      return false
    },
    showAnswers() {
      return this.hasAnswered || this.complete
    },
    userAnswers() {
      if (this.showAnswers) {
        const { user } = this.question.answers
        if (user) {
          return user
        }
      }

      return false
    },
  },
  watch: {
    showAnswers: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const slider = this.userAnswers

          if (slider) {
            this.slider = slider
          }

          this.locked = true
        } else {
          this.locked = false
        }
      }
    }
  },
  data() {
    return {
      slider: 5,
      locked: false,
      complete: false,
      status: '',
    }
  },
  methods: {
    checkCanSubmit() {
      // You can't really check anything...
      return true
    },
    async submit() {

      if (!this.checkCanSubmit()) return

      this.locked = true

      const success = await this.$store.dispatch('question/submit', {
        answer: this.slider,
        qid: this.question.qid,
      })

      if (success) {
        this.complete = true
      } else {
        this.locked = false
      }


    }
  },
}
</script>

<style lang="scss" scoped>
.average-vote-marker {
  @apply relative w-px h-4 text-center;
}

.average-vote-text {
  @apply absolute mt-1 text-14 font-serif text-grey italic whitespace-no-wrap;
  @apply tracking-serif font-light;
  top: 100%;

  &.text-center {
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>