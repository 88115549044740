import Vue from 'vue';
import { docReady, qsa, onClick } from "@/libs/helpers";
import App_VuePages from "@/vue/App_VuePages.vue";
import { store } from "@/vue/setup/setup-store";
import { setBaseUrl } from "@/vue/store/_ajax";
import { router } from "@/vue/setup/setup-router";
import PortalVue from 'portal-vue'
import "@/vue/setup/setup-layouts";

import PollProgress from "@/vue/Mini_PollProgress.vue";

Vue.use(PortalVue)

const miniComponents = {
	PollProgress,
}


// Mount the vue pages app. This will then use vue router
// so if the #vue-pages element exists, it'll start
// trying to match routes and that's what will load.

const APP = window.APP || {}
const { config: APP_Config } = APP
const { actionBaseUrl } = APP_Config


// they all use the same store also.
docReady(() => {


	// First apply our base url to the store.
	// this is a point where you can use DOM settings to setup and configure
	// the vue app, by way of store actions / commits.
	if ( actionBaseUrl ) setBaseUrl( actionBaseUrl )

	// Find a [data-mini-vue] div and if it specified a valid
	// component then load that in.

	// scrape every `data-prop-name` and pass that as a vue prop.
	// for example `data-prop-slug="myslug"` would then be passed
	// and you can use that in the MINI component as props: ['slug']
	qsa('[data-mini-vue]', (el) => {
		const c = el.getAttribute('data-mini-vue')
		const props = {}
		for (var i = 0; i < el.attributes.length; i++) {
			var attrib = el.attributes[i];
			if (attrib.specified) {
				if ( attrib.name.match(/data-prop-/) ) {
					props[ attrib.name.replace(/data-prop-/,'') ] = attrib.value
				}
			}
		}
		if (miniComponents[c]) {
			return new Vue({
				el,
				router,
				store,
				render: h => h(miniComponents[c], {
					props
				}),
			})
		}
	})

	qsa('[data-router-link]', (el) => {
		const to = el.getAttribute('data-router-link')
		el.addEventListener('click', e => {
			e.preventDefault();
			router.push(to)
		})

	})

	// Mount the main pages...
	const el = document.getElementById('vue-pages');
	if (!el) { return; }

	return new Vue({
		el,
		router,
		store,
		render: h => h(App_VuePages),
	});
});
