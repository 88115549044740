var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "transition-opacity fixed z-a1 bg-black-80 inset-0 overflow-auto scrolling-touch",
      attrs: { id: "focus-trap" }
    },
    [
      _c(
        "div",
        {
          staticClass: "global-container-xl global-padding py-20 min-h-screenh"
        },
        [
          _c(
            "div",
            {
              staticClass: "card max-w-6xl w-full m-auto relative",
              attrs: { id: "card-container" }
            },
            [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "absolute bg-white-80 inset-0 z-1 text-black flex items-center justify-center p-8"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("svg-icon", {
                            staticClass: "text-black",
                            attrs: { icon: "tail-spin", size: "8" }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "uppercase font-bold text-12 tracking-serif"
                            },
                            [_vm._v("Loading")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "relative z-0" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "z-5 absolute right-0 top-0 text-black m-4",
                  attrs: { to: "/", "aria-label": "Close" }
                },
                [_c("svg-icon", { attrs: { icon: "close", size: "4" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("store-error")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }