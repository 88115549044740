export default {
	root: true,
	state: {
		loading: false,
	},
	mutations: {
		globalLoading( state, isLoading ) {
			state.loading = isLoading 
		}
	},	
};