<template>
	<div>								
		<div class="ar-7x5">
			<div class="absolute inset-0 z-0">	
				<div ref="carousel">
					<div data-slide v-for="{ url } in slides" class="w-full h-full absolute" :key="`slide-${url}`">
						<img :src="url" class="w-full h-full object-cover object-center block"/>
					</div>
				</div>
			</div>

			<div class="absolute bottom-0 left-0 m-8 z-1">
				<flickity-alt-buttons :slot-props="{ flkty }"></flickity-alt-buttons>
			</div>
		</div>
		<div class="p-4 text-14 font-light opacity-85">
			{{ caption }}
		</div>
	</div>

</template>

<script>
export default {
	// store: ['slides'],
	
	computed: {
		slides() {
			return this.$root.store;
		}
	}
}
</script>

<script>
import Flickity from 'flickity';
import "flickity/css/flickity.css";

const defaultSettings = {
	prevNextButtons: false,
	pageDots: false,
	adaptiveHeight: false,
	wrapAround: true,
	setGallerySize: false,
	arrowShape: `M72.8,2c-0.8-1-2.3-1.1-3.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L27.1,48c-0.5,0.5-0.8,1.2-0.7,2c0,0.7,0.3,1.4,0.7,2l42.2,46
	c0.4,0.5,1.1,0.9,1.8,0.9c0.7,0,1.4-0.3,1.8-0.8c0.5-0.5,0.8-1.2,0.7-2c0-0.7-0.3-1.4-0.8-2L32.5,50l40.4-44c0.5-0.5,0.8-1.2,0.7-2
	C73.6,3.3,73.3,2.6,72.8,2z`,
};

export default {
	

	data() {
		return {
			flkty: false,
		}
	},

	computed: {
		caption() {

			if ( this.flkty ) {
				return this.slides[ this.flkty.selectedIndex ] ? this.slides[ this.flkty.selectedIndex ].caption : '';
			}

			return false;
		},
		slides() {
			return this.$root.store.slides;
		},
		
		flickitySettings() {
			return this.$root.store.flickitySettings;
		},
	},

	mounted() {
		const parsedSettings = Object.assign( {}, defaultSettings, this.flickitySettings );
		const carousel = this.$refs.carousel; 
		this.flkty = new Flickity(carousel, parsedSettings );
	},

	beforeDestroy() {
		if ( this.flkty )	{
			this.flkty.destroy();
		}
	}
}
</script>
<style scoped>
.flickity-enabled {
	height: 100%;
}
</style>