<template>
	<span class="inline-block"
		:class="classes">
		<svg xmlns="http://www.w3.org/2000/svg" class="block w-full h-full m-0 fill-current">
			<title>
				<slot />
			</title>
			<use :xlink:href="xlink"></use>
		</svg>
		
	</span>
</template>


<script>
export default {
	props: ['icon', 'size'],
	computed: {
		xlink() {
			return `#svg-${this.icon}`;
		},
		classes() {

			if ( this.size === 'none' ) {
				return '';
			}
			
			let iconSize = 4;
			if ( this.size ) {
				iconSize = this.size;
			}

			return [`w-${iconSize}`, `h-${iconSize}`];
		}
	}
};
</script>

