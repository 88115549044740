var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex items-center justify-end" },
    [
      _vm.$slots.default
        ? _c(
            "div",
            { staticClass: "mr-4 font-light text-14 text-red" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("svg-icon", {
            staticClass: "mr-2 text-black",
            attrs: { icon: "tail-spin", size: "8" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-white button bg-theme-highlight" }, [
            _vm._v("Saving...")
          ])
        : _vm.nextUrl && _vm.complete
        ? _c(
            "router-link",
            {
              staticClass: "text-white button bg-theme-highlight",
              attrs: { to: _vm.nextUrl }
            },
            [_vm._v("Next")]
          )
        : !_vm.complete
        ? _c(
            "button",
            {
              staticClass: "text-white button bg-theme-highlight",
              on: {
                click: function($event) {
                  return _vm.$emit("submit")
                }
              }
            },
            [_vm._v("Confirm")]
          )
        : _vm.entryType == "pollsDataCapture"
        ? _c(
            "router-link",
            {
              staticClass: "text-white button bg-theme-highlight",
              attrs: { to: _vm.details }
            },
            [_vm._v("Next")]
          )
        : _vm.final
        ? _c(
            "router-link",
            {
              staticClass: "text-white button bg-theme-highlight",
              attrs: { to: _vm.final }
            },
            [_vm._v("Next")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }