var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md:flex items-stretch" }, [
    _c(
      "div",
      {
        staticClass:
          "flex flex-col justify-between text-right flex-grow-0 relative flex-shrink-0 \n    md:border-r border-blue border-theme-highlight my-1 pr-2 md:pr-4"
      },
      [
        _c("div", { staticClass: "md:-mt-1 text-14 font-light" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.topLabel ? _vm.topLabel : "High") +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "hidden md:block absolute right-0 nubbin rounded-full bg-blue bg-theme-highlight top-0"
        }),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "hidden md:block absolute right-0 nubbin rounded-full bg-blue bg-theme-highlight bottom-0"
        }),
        _vm._v(" "),
        _c("div", { staticClass: "hidden md:block -mb-1 text-14 font-light" }, [
          _vm._v(_vm._s(_vm.bottomLabel ? _vm.bottomLabel : "Low"))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "md:w-c8 flex-grow flex-shrink-0 md:ml-4" },
      [
        _c(
          "Draggable",
          {
            attrs: { disabled: _vm.locked, group: "people" },
            on: {
              start: function($event) {
                _vm.drag = true
              },
              end: function($event) {
                _vm.drag = false
              }
            },
            model: {
              value: _vm.elements,
              callback: function($$v) {
                _vm.elements = $$v
              },
              expression: "elements"
            }
          },
          _vm._l(_vm.elements, function(element) {
            return _c(
              "div",
              {
                key: element.id,
                staticClass: "draggable relative pr-32",
                class: {
                  locked: _vm.locked,
                  "group hover:text-theme-highlight": !_vm.drag && !_vm.locked
                }
              },
              [
                _vm._v("\n        " + _vm._s(element.text) + "\n        "),
                _c(
                  "div",
                  {
                    staticClass:
                      "right-0 top-0 bottom-0 absolute z-0 flex items-center p-4"
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "group-hover:text-theme-highlight",
                      attrs: {
                        icon: _vm.locked ? "circle-tick" : "drag",
                        size: "4"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "md:hidden text-right my-1 pr-2 md:-mb-1 text-14 font-light"
          },
          [_vm._v(_vm._s(_vm.bottomLabel ? _vm.bottomLabel : "Low"))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }