var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.flkty
    ? _c("div", { staticClass: "flex text-white items-center" }, [
        _c(
          "button",
          {
            staticClass: "no-mouse-focus mr-3 circle-button",
            attrs: { "aria-label": "Previous Image" },
            on: { click: _vm.previous }
          },
          [_c("svg-icon", { attrs: { size: "4", icon: "arrow-left" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "no-mouse-focus mr-4 circle-button",
            attrs: { "aria-label": "Next Image" },
            on: { click: _vm.next }
          },
          [_c("svg-icon", { attrs: { size: "4", icon: "arrow-right" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "font-light text-15 mt-1 leading-none" }, [
          _vm._v(_vm._s(_vm.current) + " / " + _vm._s(_vm.total))
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }