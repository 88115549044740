<template>
  <div class="">
    <div
      class="mb-1 text-14 font-light opacity-85 text-theme-fg"
    >Question {{ question }} of {{ total }}</div>
    <div class="w-full h-2 bg-lightgrey-d overflow-hidden rounded-lg">
      <div
        :style="{
            transform: `scaleX(${progress})`,
            transition: `transform .3s ease`,
            transformOrigin: `left`
          }"
        class="bg-blue bg-theme-highlight h-full w-full ml-0 mr-auto"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['progress', 'question', 'total']
}
</script>

<style>
</style>