<template>
	<div class="flex text-white items-center" v-if="flkty">
		<button class="no-mouse-focus mr-3 circle-button" aria-label="Previous Image" @click="previous">
			<svg-icon size="4" icon="arrow-left"  />
		</button>
		<button class="no-mouse-focus mr-4 circle-button" aria-label="Next Image" @click="next">
			<svg-icon size="4" icon="arrow-right"  />
		</button>
		<div class="font-light text-15 mt-1 leading-none">{{ current }} / {{ total }}</div>
	</div>
</template>
<script>
export default {
	props: ['slotProps'],
	computed: {
		flkty() {
			if ( this.slotProps.flkty ) {
				return this.slotProps.flkty
			}

			return false;
		},

		total() {
			if ( this.slotProps.flkty ) {
				return this.slotProps.flkty.cells.length;
			}

			return false;
		},

		current() {
			if ( this.slotProps.flkty ) {
				return this.slotProps.flkty.selectedIndex + 1;
			}

			return false;
		}
	},

	methods: {
		previous() {
			return this.flkty.previous();
		},
		next() {
			return this.flkty.next();
		},
	}
}
</script>
<style scoped>
.circle-button {
	@apply w-8 h-8 bg-black-10 items-center justify-center inline-flex border border-current rounded-full;
}
</style>