<template>
	<div class="hidden">
		<!-- 
		This page just exists to enable us to add the landing 'have your say'
		page to the vue-router and hook into the history state.
		-->
	</div>
</template>

<script>

export default {
	
	layout: 'invisible',


}
</script>