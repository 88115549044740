<template>
	<div class="inset-0 absolute">
		<div ref="carousel" class="absolute inset-0 z-0">
			<slot />
		</div>

		<slot v-bind:flkty="flkty" name="chrome" />
		
	</div>
</template>
<script>
import Flickity from 'flickity';
import "flickity/css/flickity.css";

const defaultSettings = {
	prevNextButtons: false,
	pageDots: false,
	adaptiveHeight: false,
	wrapAround: true,
	setGallerySize: false,
	arrowShape: `M72.8,2c-0.8-1-2.3-1.1-3.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L27.1,48c-0.5,0.5-0.8,1.2-0.7,2c0,0.7,0.3,1.4,0.7,2l42.2,46
	c0.4,0.5,1.1,0.9,1.8,0.9c0.7,0,1.4-0.3,1.8-0.8c0.5-0.5,0.8-1.2,0.7-2c0-0.7-0.3-1.4-0.8-2L32.5,50l40.4-44c0.5-0.5,0.8-1.2,0.7-2
	C73.6,3.3,73.3,2.6,72.8,2z`,
};

export default {
	

	computed: {
		flickitySettings() {
			return this.$root.flickitySettings;
		}
	},

	data() {
		return {
			flkty: false,
		}
	},

	mounted() {
		const parsedSettings = Object.assign( {}, defaultSettings, this.flickitySettings );
		const carousel = this.$refs.carousel; 
		this.flkty = new Flickity(carousel, parsedSettings );
	},

	beforeDestroy() {
		if ( this.flkty )	{
			this.flkty.destroy();
		}
	}
}
</script>
<style scoped>
.flickity-enabled {
	height: 100%;
}


</style>