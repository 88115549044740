<template>
  <PollStep
    :class="{
      'opacity-0': leave,
      'transition-delay': !leave,
    }"
    class="transition-opacity transition-fast"
    :key="`step-${step}`"
    :step="step"
  />
</template>

<script>
import PollStep from '@/vue/components/PollStep.vue'
import { mapState } from 'vuex'
export default {
  components: {
    PollStep,
  },
  data() {
    return {
      leave: true,
    }
  },
  computed: {
    step() {
      return this.$route.params.step
    },
    ...mapState('polls', [
      'title',
      'slug',
      'id',
      'questions',
    ]),
  },
  watch: {
    $route(to, from) {
      // console.log( "Route watcher", { to,from } )
      this.leave = true
      this.$nextTick( () => this.leave = false )
    }
  },

  beforeRouteLeave(to,from,next) {

    // We're closing...
    if ( to.path === "/" ) {
      // console.log( "rmove fixboxy")
      document.body.classList.remove('fix-body')
    }

    this.leave = true
    setTimeout(() => {
      next()
    }, 200)
  },
  beforeRouteUpdate(to, from, next) {
    this.leave = true
    setTimeout(() => {
      next()
    }, 200)
  },
  mounted() {
    this.leave = true
    this.$nextTick( () => document.body.classList.add('fix-body') )
    setTimeout(() => {
      this.leave = false
    }, 500)
  },
  created() {
    this.$store.dispatch('polls/load', this.$route.params.poll);
  },
}
</script>