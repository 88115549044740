

export default {
	namespaced:true,
	state: {
		error: false,
		message: '',
	},
	mutations: {
		alert ( state, error ) {
			state.error = true;
			state.message = error;
		},
		reset ( state ) {
			state.error = false;
			state.message = '';
		}
	}
};