import Vue from 'vue';
import { docReady, qsa, onClick } from "@/libs/helpers";

docReady(() => {
	qsa('[data-basic-vue]', el => {

		const dataStore = el.getAttribute('data-vue-store');
		let store;

		if ( dataStore ) {
			store = JSON.parse( dataStore );
		} else {
			store = {};
		}

		return new Vue({
			el,
			data: {
				store
			},
			template: el.innerHTML,
		});
	});
});