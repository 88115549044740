<template>
  <div class="p-8 bg-black-20 fixed inset-0 overflow-auto scrolling-touch">
    <h1>Components</h1>

    <section>
      <h2>Multi Choice Slide</h2>
      <MultipleChoiceSlide
        question="What type of independent shops would you like to see?"
        :multiple="true"
        :locked="false"
        :show-answers="false"
        @selected="chooseOptions"
        :options="questionsToOrder"
      />
    </section>
    <section>
      <h2>Multiple Choice:</h2>
      <div class="my-4">
        <MultipleChoice
          :multiple="true"
          :locked="true"
          :force-selected="[
            111
          ]"
          :show-answers="true"
          @selected="chooseOptions"
          :options="questionsToOrder"
        />
      </div>

      <div class="my-4">
        <h2>Result of component emissions:</h2>
        <div>
          Chosen:
          <ul>
            <li v-for="(chosen,i) in computedChosenOptions" :key="i">{{ chosen.text }}</li>
          </ul>
        </div>
      </div>
    </section>

    <section>
      <h2>Slider:</h2>
      <div class="my-4">
        <AgreeSlider :min="0" :max="10" v-model="slider" />
      </div>

      <div class="my-4">
        <h2>Result of component emissions:</h2>
        <div>Slider: {{ slider }}</div>
      </div>
    </section>

    <section>
      <h2>Draggable:</h2>
      <div class="my-4">
        <ChooseOrder @change-order="changedOrder" :disabled="locked" :options="questionsToOrder" />
        <button
          class="button text-blue"
          @click="locked = !locked"
        >{{ locked ? 'unlock dragging' : 'lock dragging' }}</button>
      </div>

      <div class="my-4">
        <h2>Result of component emissions:</h2>
        <div v-for="(question,i) in questionsToOrder" :key="i">{{ i }} - {{ question.text }}</div>
      </div>
    </section>
  </div>
</template>

<script>

import ChooseOrder from "@/vue/components/inputs/ChooseOrder.vue"
import AgreeSlider from "@/vue/components/inputs/AgreeSlider.vue"

import MultipleChoice from "@/vue/components/inputs/MultipleChoice.vue"
import MultipleChoiceSlide from "@/vue/components/slides/MultipleChoiceSlide.vue"




export default {
  name: "Pages__Test",
  layout: 'test',
  components: {

    MultipleChoiceSlide,
    AgreeSlider,
    ChooseOrder,
  },
  data() {
    return {
      slider: 5,
      locked: false,

      chosenOptions: [

      ],
      multipleChoiceOptions: [

      ],

      questionsToOrder: [
        { percent: 22, i: 0, id: 111, image: "http://place-puppy.com/200x200", text: "Colorful finish" },
        { percent: 20, i: 1, id: 222, image: "http://place-puppy.com/201x201", text: "Stall Markings" },
        { percent: 44, i: 2, id: 333, image: "http://place-puppy.com/202x202", text: "York Stone Paving" },
        { percent: 2, i: 3, id: 444, image: "http://place-puppy.com/203x203", text: "Traditional Dobbles, granite" },
        // { i: 4, id: 555, image: "http://place-puppy.com/204x204", text: "A new Pupper Arrives" },
      ]
    }
  },
  computed: {
    computedChosenOptions() {
      return this.questionsToOrder.filter(({ id }) => this.chosenOptions.indexOf(id) > -1)
    }
  },
  methods: {
    changedOrder(questions) {
      this.questionsToOrder = questions
    },
    chooseOptions(chosenOptions) {
      this.chosenOptions = chosenOptions;
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  @apply my-8 border-black-20 border p-8 w-full shadow bg-white;
}
h2 {
  @apply uppercase font-bold text-24 mt-8 mb-4;
}
</style>