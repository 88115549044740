var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "mb-1 text-14 font-light opacity-85 text-theme-fg" },
      [_vm._v("Question " + _vm._s(_vm.question) + " of " + _vm._s(_vm.total))]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-full h-2 bg-lightgrey-d overflow-hidden rounded-lg" },
      [
        _c("div", {
          staticClass: "bg-blue bg-theme-highlight h-full w-full ml-0 mr-auto",
          style: {
            transform: "scaleX(" + _vm.progress + ")",
            transition: "transform .3s ease",
            transformOrigin: "left"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }