<template>
  <div class="md:flex items-stretch">
    <div
      class="flex flex-col justify-between text-right flex-grow-0 relative flex-shrink-0 
      md:border-r border-blue border-theme-highlight my-1 pr-2 md:pr-4"
    >
      <div class="md:-mt-1 text-14 font-light">
          {{ topLabel ? topLabel : "High" }}
      </div>
      <div class="hidden md:block absolute right-0 nubbin rounded-full bg-blue bg-theme-highlight top-0"></div>
      <div class="hidden md:block absolute right-0 nubbin rounded-full bg-blue bg-theme-highlight bottom-0"></div>
      <div class="hidden md:block -mb-1 text-14 font-light">{{ bottomLabel ? bottomLabel : "Low" }}</div>
    </div>
    <div class="md:w-c8 flex-grow flex-shrink-0 md:ml-4">
      <!-- V1 -->
      <Draggable
        :disabled="locked"
        v-model="elements"
        group="people"
        @start="drag=true"
        @end="drag=false"
      >
        <div
          :class="{
          'locked': locked,
          'group hover:text-theme-highlight': !drag && !locked,
        }"
          class="draggable relative pr-32"
          v-for="element in elements"
          :key="element.id"
        >
          {{element.text}}
          <div class="right-0 top-0 bottom-0 absolute z-0 flex items-center p-4">
            <svg-icon
              class="group-hover:text-theme-highlight"
              :icon="locked ? 'circle-tick' : 'drag'"
              size="4"
            />
          </div>
        </div>
      </Draggable>

      <div class="md:hidden text-right my-1 pr-2 md:-mb-1 text-14 font-light">{{ bottomLabel ? bottomLabel : "Low" }}</div>

      <!-- <div class="p-8 bg-black-10 border-black-20">
      <button class="button" @click="shuffleOrder">Shuffle Order</button>
      </div>-->
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import Draggable from 'vuedraggable'

export default {
  props: {
    fixedOrder: {
      type: [Boolean, Array],
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    topLabel: {
      type: [ String, Boolean ],
      default: "High",
    },
    bottomLabel: {
      type: [ String, Boolean ],
      default: "Low",
    },
    options: {
      type: Array,
      required: true,
      validator: value => {
        let valid = true

        value.forEach(({ text, id }) => {
          // In other words, each value must be an object
          // with valid id and text. The other props are arbitrary.

          // text is used to show the user, and 
          // id to emit an array of ids.
          if (!text || !id) {
            valid = false
          }
        })

        return valid
      }
    }
  },
  components: {
    Draggable
  },
  watch: {
    options: {
      immediate: true,
      handler(q) {
        if ( q ) {
          this.loadOptions(q)
        }
      }
    },
    drag(dragging) {
      if (!dragging) {
        this.changeOrder()
      }
    },
    fixedOrder(newOrder) {
      this.calcOrder()
    }
  },
  computed: {
    locked() {
      return (this.fixedOrder && this.fixedOrder.length) || this.disabled
    },
  },
  data() {
    return {
      drag: false,
      elements: [],
    }
  },
  mounted() {
    // this.$emit("change-order", this.elements) 
  },  
  methods: {
    loadOptions() {
      this.elements = [...this.options]
      this.calcOrder()
    },
    calcOrder() {
      if (this.fixedOrder && this.fixedOrder.length) {

        const newArray = []

        this.fixedOrder.forEach(n => {
          const found = this.elements.filter(({ i }) => i == n)
          if (found) {
            newArray.push(found[0])
          }

        })

        // Did we lose any elements that weren't in the supplied order?
        this.elements.forEach((element) => {
          const found = newArray.filter(({ id }) => id == element.id)

          if (!found) {
            newArray.push(element)
          }

        })

        this.elements = newArray

      }
    },
    shuffleOrder() {
      randomise(this.elements)
      this.changeOrder()
    },
    changeOrder() {
      this.$emit("change-order", this.elements)
    },
  }
}
</script>

<style lang="scss" scoped>
.nubbin {
  width: 5px;
  height: 5px;
  margin-right: -3px;
}

.draggable {
  border-radius: 6px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: rgb(251, 251, 251);
  user-select: none;
  @apply p-4 my-2 cursor-move;

  &.locked {
    @apply cursor-default;
  }
}
</style>