<template>
  <div>
    <div class="md:flex items-stretch mt-8">
      <div class="md:w-c5 self-stretch flex flex-col">
        <div class="w-full max-w-70 flex-grow-0">
          <base-slide-title tag="h2" class>{{ question.text }}</base-slide-title>
        </div>
        <div class="mt-auto mb-0 mt-8" v-if="question.image">
          <img :src="question.image.url" :alt="question.image.title" class="w-full h-auto block" />
        </div>
      </div>
      <div class="md:w-c7 md:mt-0 mt-8 md:pl-8 flex flex-col">
        
        
        <transition name="fade-simple" mode="out-in" >          
          <p v-if="showAnswers" class="mb-4 flex-grow-0 flex-shrink-0 text-16 font-light max-w-60 text-grey leading-medium">Thanks for letting us know!</p>
          <label
            v-else
              class="mb-4 flex-grow-0 flex-shrink-0 text-16 font-light max-w-60 text-grey leading-medium"
              :for="`textarea-${question.qid}-${question.pollId}`"
            >Enter your answer below:</label>
        </transition>

        <transition name="fade-simple" mode="out-in">
          <div v-if="showAnswers" class="textarea-fixed">{{ submittedAnswer }}</div>
          <div v-else class="flex flex-col flex-grow" key="textarea-form">
            <textarea
              class="min-h-40 textarea flex-grow"
              v-model="answer"
              :id="`textarea-${question.qid}-${question.pollId}`"
            ></textarea>
          </div>
        </transition>

      </div>
    </div>
    <div class="text-right mt-8">
      <SaveButton
        @submit="submit"
        :loading="$store.state.question.loading"
        :complete="showAnswers"
        :next-url="nextUrl"
      >
        <template v-if="status">{{ status }}</template>
      </SaveButton>
    </div>
  </div>
</template>

<script>

import SaveButton from '@/vue/components/inputs/SaveButton.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    question: {
      type: Object,
    },
    nextUrl: {
      type: [String, Boolean],
      required: false,
    },
  },
  components: {
    SaveButton,
  },

  computed: {
    ...mapGetters('question', [
      'hasAnswered',
    ]),
    showAnswers() {
      return this.hasAnswered || this.complete
    },
    submittedAnswer() {
      const { answers } = this.question
      if (answers) {
        const { user } = answers
        return user
      }

      return false
    }
  },

  data() {
    return {
      answer: '',
      status: '',
      locked: false,
      complete: false,
    }
  },
  watch: {
      answer(a) {
        if ( a ) {
          this.status = ''
        }
      }
  },
  methods: {
 
    checkCanSubmit() {
      if ( !this.answer ) {
        this.status = "please give an answer above"

        return false
      }

      return true
    },
    async submit() {

      if ( !this.checkCanSubmit() ) return

      this.locked = true

      const success = await this.$store.dispatch('question/submit', {
        answer: this.answer,
        qid: this.question.qid,
      })

      if ( success ) {
        this.complete = true
      } else {
        this.locked = false
      }

    }
  },
}
</script>

<style scoped lang="scss">
.textarea {
  @apply p-4 border-lightgrey-d border rounded-lg w-full flex-grow text-18 font-sans;

  &:focus {
    outline: 0;    
    @apply border-theme-highlight;
    box-shadow: 0 0 2px 0 var(--theme-highlight);
  }
}

.textarea-fixed {
  @apply p-4 bg-lightgrey-e-40 text-black-70 italic border border-transparent rounded-lg w-full flex-grow text-18 font-sans;
}
</style>