<template>
	<div
		class="inset-0 flex items-center justify-center fixed bg-black-70 z-a6"
	 v-if="error">
	 	<div class="p-8 bg-white shadow-card">
			{{ message }}
			<div class="mt-3">
				<button @click="dismiss" class="button bg-red text-white">Dismiss</button>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
	computed: {
		...mapState({
			error: state => state.errors.error,
			message: state => state.errors.message,
		})
	},
	methods: {
		dismiss() {
			this.$store.commit('errors/reset');
		}
	}
}
</script>