<template>
  <div />
</template>

<script>
import PollStep from '@/vue/components/PollStep.vue'

// import store to use in navigation guards.
import { store } from '@/vue/setup/setup-store'

import { mapState } from 'vuex'
export default {
  components: {
    PollStep,
  },
  data() {
    return {
      step: false,
      leave: false,
    }
  },
  computed: {
    ...mapState('polls', [
      'title',
      'slug',
      'id',
      'questions',
    ]),
  },
  watch: {
    questions: {
      immediate: true,
      handler(questions) {
        // If we have questions, then loop through,
        // find the earliest _unanswered_ question and redirect
        // to that. 

        // this is only something we do on the index page. 
        // Each question load directly will go to it, so a user _can_ try to go back
        // manually if they figure out the URLs.
        let newStep = false

        if (questions.length) {
          questions.forEach(({ i, text, answers }) => {
            if (!answers.hasAnswered && !newStep) {
              newStep = i
            }
          })

          if (!newStep) newStep = 1
          this.step = newStep
          this.$router.replace(`/${this.$route.params.poll}/${this.step}/`)
        } else {
          // Ensure a reset...
          this.step = false
        }

      }
    }
  },
  
  beforeRouteUpdate(to, from, next) {
    this.leave = true
    setTimeout(() => {
      next()
      this.leave = false
    }, 400)
  },
  mounted() {

  },
  async beforeRouteEnter (to, from, next) {
    
    setTimeout( () => document.body.classList.add('fix-body'), 200 )
    
    
    if ( to.params.poll !== from.params.poll ) {
      store.commit('polls/unload')
      store.dispatch('polls/load', to.params.poll )
      next()
    } else {
      next()
    }    
  }
}
</script>