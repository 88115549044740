<template>
	<div>
		I'm the carousel.
	</div>
</template>
<script>
export default {
	store: [''],
	mounted() {

	},
}
</script>