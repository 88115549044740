var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "md:flex items-stretch mt-8" }, [
      _c("div", { staticClass: "md:w-c5 self-stretch flex flex-col" }, [
        _c(
          "div",
          { staticClass: "w-full max-w-70 flex-grow-0" },
          [
            _c("base-slide-title", { attrs: { tag: "h2" } }, [
              _vm._v(_vm._s(_vm.question.text))
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm.question.image
          ? _c("div", { staticClass: "mt-auto mb-0 mt-8" }, [
              _c("img", {
                staticClass: "w-full h-auto block",
                attrs: {
                  src: _vm.question.image.url,
                  alt: _vm.question.image.title
                }
              })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "md:w-c7 md:mt-0 mt-8 md:pl-8 flex flex-col" },
        [
          _c("transition", { attrs: { name: "fade-simple", mode: "out-in" } }, [
            _vm.showAnswers
              ? _c(
                  "p",
                  {
                    staticClass:
                      "mb-4 flex-grow-0 flex-shrink-0 text-16 font-light max-w-60 text-grey leading-medium"
                  },
                  [_vm._v("Thanks for letting us know!")]
                )
              : _c(
                  "label",
                  {
                    staticClass:
                      "mb-4 flex-grow-0 flex-shrink-0 text-16 font-light max-w-60 text-grey leading-medium",
                    attrs: {
                      for:
                        "textarea-" +
                        _vm.question.qid +
                        "-" +
                        _vm.question.pollId
                    }
                  },
                  [_vm._v("Enter your answer below:")]
                )
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade-simple", mode: "out-in" } }, [
            _vm.showAnswers
              ? _c("div", { staticClass: "textarea-fixed" }, [
                  _vm._v(_vm._s(_vm.submittedAnswer))
                ])
              : _c(
                  "div",
                  {
                    key: "textarea-form",
                    staticClass: "flex flex-col flex-grow"
                  },
                  [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.answer,
                          expression: "answer"
                        }
                      ],
                      staticClass: "min-h-40 textarea flex-grow",
                      attrs: {
                        id:
                          "textarea-" +
                          _vm.question.qid +
                          "-" +
                          _vm.question.pollId
                      },
                      domProps: { value: _vm.answer },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.answer = $event.target.value
                        }
                      }
                    })
                  ]
                )
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-right mt-8" },
      [
        _c(
          "SaveButton",
          {
            attrs: {
              loading: _vm.$store.state.question.loading,
              complete: _vm.showAnswers,
              "next-url": _vm.nextUrl
            },
            on: { submit: _vm.submit }
          },
          [_vm.status ? [_vm._v(_vm._s(_vm.status))] : _vm._e()],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }