import { mapsReady } from "@/libs/maps-ready";
import { qsa, docReady } from "@/libs/helpers";



docReady( () => {

	qsa('[data-map]', mapElement => {

		mapsReady().then( () => {

			const location = APP.mapLocation;			

			if ( !location ) {
				return;
			}			
	
			let { zoom } = location;

			const lat = parseFloat(location.lat); 
			const lng = parseFloat(location.lng);

			// The map, centered at Uluru
			const map = new google.maps.Map(
				mapElement, 
				{
					disableDefaultUI: true,
					zoom, 
					center: { 
						lat,lng
					} 
				}
			);

			const size = 30;
			const image = {
				url: '/assets/img/location-pin.svg',
				size: new google.maps.Size(size, size),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(size/2, size/2),
				scaledSize: new google.maps.Size(size, size)
			};

			// The marker, positioned at Uluru
			const marker = new google.maps.Marker({
				position: { lat, lng }, 
				map,
				size: new google.maps.Size(20,20),
				icon: image,
			});
		});
	})
});

