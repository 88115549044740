<!-- 

Note that this isn't really a "default" template
by normal means, this is a default for the POLLS
which means that it has an overlay, 

-->

<template>
    <div id="focus-trap" class="transition-opacity fixed z-a1 bg-black-80 inset-0 overflow-auto scrolling-touch">
      <div class="global-container-xl global-padding py-20 min-h-screenh">
        <!-- Beginning of card -->
        <div id="card-container" class="card max-w-6xl w-full m-auto relative">
          <div
            v-if="loading"
            class="absolute bg-white-80 inset-0 z-1 text-black flex items-center justify-center p-8"
          >
            <div class="text-center">
              <svg-icon icon="tail-spin" class="text-black" size="8" />
              <div class="uppercase font-bold text-12 tracking-serif">Loading</div>
            </div>
          </div>

          <!-- NAV BAR -->
          <div class="relative z-0">
            <slot />
          </div>

          <router-link class="z-5 absolute right-0 top-0 text-black m-4" to="/" aria-label="Close">
            <svg-icon icon="close" size="4" />
          </router-link>
        </div>
      </div>
      <store-error />
    </div>
</template>

<script>
import StoreError from "@/vue/components/StoreError.vue";
import { mapState } from 'vuex'
export default {
  components: {
    StoreError,
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    ...mapState('polls', [
      'title',
      'slug',
      'id',
      'questions',
    ]),

    ...mapState({
      loading: state => state.polls.loading
    }),

    nextStep() {
      const i = parseInt(this.step) + 1;
      if (i - 1 === this.totalQuestions) {
        return false
      }

      return i
    },

    // Not gonna be needed but w/e it's useful for debuggin
    prevUrl() {
      if (parseInt(this.$route.params.step) === 1) return false;
      return `/${this.$route.params.poll}/${parseInt(this.$route.params.step) - 1}`
    },

    nextUrl() {
      if (!this.nextStep) return false;
      return `/${this.$route.params.poll}/${this.nextStep}`
    }
  },

  
}
</script>

<style>
.fix-body {
  overflow: hidden;
  height: 100%;
}
</style>

<style scoped>
.router-link {
  @apply border-2 border-transparent bg-black-10 px-2 m-1;
}
.router-link.router-link-active {
  @apply border-2 border-skyblue;
}
.router-link.router-link-exact-active {
  @apply font-bold text-skyblue;
}

.tab-link {
  transition: opacity 0.3s ease, background-color 0.3s ease;
}
</style>