var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "p-8 bg-black-20 fixed inset-0 overflow-auto scrolling-touch"
    },
    [
      _c("h1", [_vm._v("Components")]),
      _vm._v(" "),
      _c(
        "section",
        [
          _c("h2", [_vm._v("Multi Choice Slide")]),
          _vm._v(" "),
          _c("MultipleChoiceSlide", {
            attrs: {
              question: "What type of independent shops would you like to see?",
              multiple: true,
              locked: false,
              "show-answers": false,
              options: _vm.questionsToOrder
            },
            on: { selected: _vm.chooseOptions }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("section", [
        _c("h2", [_vm._v("Multiple Choice:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "my-4" },
          [
            _c("MultipleChoice", {
              attrs: {
                multiple: true,
                locked: true,
                "force-selected": [111],
                "show-answers": true,
                options: _vm.questionsToOrder
              },
              on: { selected: _vm.chooseOptions }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "my-4" }, [
          _c("h2", [_vm._v("Result of component emissions:")]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n        Chosen:\n        "),
            _c(
              "ul",
              _vm._l(_vm.computedChosenOptions, function(chosen, i) {
                return _c("li", { key: i }, [_vm._v(_vm._s(chosen.text))])
              }),
              0
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", [
        _c("h2", [_vm._v("Slider:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "my-4" },
          [
            _c("AgreeSlider", {
              attrs: { min: 0, max: 10 },
              model: {
                value: _vm.slider,
                callback: function($$v) {
                  _vm.slider = $$v
                },
                expression: "slider"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "my-4" }, [
          _c("h2", [_vm._v("Result of component emissions:")]),
          _vm._v(" "),
          _c("div", [_vm._v("Slider: " + _vm._s(_vm.slider))])
        ])
      ]),
      _vm._v(" "),
      _c("section", [
        _c("h2", [_vm._v("Draggable:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "my-4" },
          [
            _c("ChooseOrder", {
              attrs: { disabled: _vm.locked, options: _vm.questionsToOrder },
              on: { "change-order": _vm.changedOrder }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button text-blue",
                on: {
                  click: function($event) {
                    _vm.locked = !_vm.locked
                  }
                }
              },
              [_vm._v(_vm._s(_vm.locked ? "unlock dragging" : "lock dragging"))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "my-4" },
          [
            _c("h2", [_vm._v("Result of component emissions:")]),
            _vm._v(" "),
            _vm._l(_vm.questionsToOrder, function(question, i) {
              return _c("div", { key: i }, [
                _vm._v(_vm._s(i) + " - " + _vm._s(question.text))
              ])
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }