<template>
  <div>
    <component :is="slideComponent" v-if="slideComponent" :next-url="nextUrl" :question="question" />
    <div class="bg-red text-white font-bold p-2 my-8 rounded" v-else-if="!loading">
      We couldn't load that question
      <svg-icon icon="sad" class="fill-current inline-block align-middle" size="7" />
    </div>

    <portal to="debug">
      <div class="debug">
        <div>Component Used:</div>
        <div>{{ question.type }} => {{ slideComponent }}.vue</div>
      </div>
    </portal>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import ProgressBar from "@/vue/components/ProgressBar.vue";
import MultipleChoiceSlide from "@/vue/components/slides/MultipleChoiceSlide.vue";
import RangeSlide from "@/vue/components/slides/RangeSlide.vue";
import SortSlide from "@/vue/components/slides/SortSlide.vue";
import TextSlide from "@/vue/components/slides/TextSlide.vue";

export default {

  components: {
    ProgressBar,
    MultipleChoiceSlide,
    RangeSlide,
    SortSlide,
    TextSlide,
  },
  props: {
    step: {
      type: [Number, String],
      validator: val => typeof parseInt(val) === "number",
      default: 1,
      required: false,
    }
  },
  watch: {
    step: {
      immediate: true,
      handler(newVal) {
        this.updateStep()
      }
    }
  },
  created() {

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'polls/load') {
        this.updateStep()
      }
    })
  },
  computed: {
    slideComponent() {
      switch (this.question.type) {
        case 'multipleChoice':
        case 'singleChoice':
          return 'MultipleChoiceSlide'
          break;

        case 'range':
          return 'RangeSlide'
          break;

        case 'sortable':
          return 'SortSlide'
          break;

        case 'freeText':
          return 'TextSlide'
          break;

        default:
          return false;
          break;
      }

    },
    ...mapState('polls', [
      'title',
      'slug',
      'id',
      'questions',
      'loading',
    ]),
    ...mapState('question', [
      'question',
    ]),    
    ...mapGetters('polls', [
      'totalQuestions',
    ]),

    nextStep() {
      const i = parseInt(this.step) + 1;
      if (i - 1 === this.totalQuestions) {
        return false
      }

      return i
    },

    nextUrl() {
      if (!this.nextStep) return false;
      return `/${this.$route.params.poll}/${this.nextStep}/`
    },

    progress() {
      return parseInt(this.step) / this.totalQuestions
    },

   
  },

  methods: {
    updateStep() {
      const questions = this.questions
      if (questions.length) {
        const activeQuestion = questions[this.step - 1]
        if (activeQuestion) {
          this.$store.commit('question/setActiveQuestion', activeQuestion);
        } else {
          // 404?
          this.$store.commit('errors/alert', `${this.step} - A Step Too Far!`);
        }
      }
    }
  }

}
</script>

<style>
</style>