var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v("Step 2")]),
    _vm._v(" "),
    _c("div", [
      _vm._v(
        "\n\t\tSome Value: " + _vm._s(_vm.controllerState.someValue) + "\n\t"
      )
    ]),
    _vm._v(" "),
    _c("div", [
      _vm._v(
        "\n\t\tRandom Value From Server: " +
          _vm._s(_vm.controllerState.randomValue) +
          "\n\t"
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }