var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "transition-alpha",
      class: {
        invisible: _vm.total <= 0
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "flex justify-between font-light text-14 text-black-80"
        },
        [
          _c("div", [
            _vm._v(_vm._s(_vm.total) + " Question"),
            _vm.total > 1 ? _c("span", [_vm._v("s")]) : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _vm._v(_vm._s(_vm.count) + "/" + _vm._s(_vm.total))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full h-2 overflow-hidden rounded-lg bg-lightgrey-d" },
        [
          _c("div", {
            staticClass:
              "w-full h-full ml-0 mr-auto bg-theme-highlight scalex-0",
            style: {
              transform: "scaleX(" + _vm.count / _vm.total + ")"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }