import Vue from 'vue';
import Vuex from 'vuex';
import index from "@/vue/store/index";
import question from "@/vue/store/question";
import polls from "@/vue/store/polls";
import errors from "@/vue/store/errors";
Vue.use(Vuex);
export const store = new Vuex.Store({
	modules: {
		index,
		errors,
		question,
		polls,
	},
});


// Set some global collective states...
store.watch( 
		state => state.polls.loading || state.question.loading,
		( isLoading ) => {
			store.commit('globalLoading', !!isLoading, { root: true } )
		}
)


export default { store };