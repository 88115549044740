
let mapsAreReady = false;
let mapsLoading = false;


const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${APP.mapsApi}&callback=initGoogleMaps`;

let timer;
let _promise

const getGlobalPromise = () => {

	if (_promise) {
		return _promise;
	}

 	_promise = new Promise( ( res, rej ) => {

		if ( mapsAreReady ) {
			clearTimeout( timer );
			res();
			return;
		}

		if ( !mapsLoading ) {
			mapsLoading = true;
			const s = document.createElement('script');
			s.setAttribute('defer','defer');
			s.setAttribute('async','async');
			s.setAttribute('type','text/javascript');
			s.setAttribute('src', mapsUrl );
			const h = document.querySelector('head');
			h.append( s );

			window.initGoogleMaps = function() {
				clearTimeout( timer );
				mapsAreReady = true;
				res();
			};
		}

		timer = setTimeout( () => {
			rej("Maps didn't load");
		}, 20000 );	

	});

	return _promise;
};

export const mapsReady = () => {
	return getGlobalPromise();
};

export default { mapsReady };



