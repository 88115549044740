import Vue from 'vue';
import { docReady, qsa } from "@/libs/helpers";
const HomepageCarousel = () => import('@/vue/HomepageCarousel.vue')

docReady(() => {
  qsa(`[data-homepage-carousel]`, el => {

    const dataStore = el.getAttribute('data-vue-store');
    let store;

    if (dataStore) {
      store = JSON.parse(dataStore);
    } else {
      store = {};
    }

    new Vue({
      el,
      data: {
        store
      },
      render: h => h(HomepageCarousel, {
        props: {
          slides: store.slides,
          showCaptions: store.showCaptions,
          flickitySettings: store.flickitySettings
        }
      }),
    });
  });
});