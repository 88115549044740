var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PollStep", {
    key: "step-" + _vm.step,
    staticClass: "transition-opacity transition-fast",
    class: {
      "opacity-0": _vm.leave,
      "transition-delay": !_vm.leave
    },
    attrs: { step: _vm.step }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }