var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.slideComponent
        ? _c(_vm.slideComponent, {
            tag: "component",
            attrs: { "next-url": _vm.nextUrl, question: _vm.question }
          })
        : !_vm.loading
        ? _c(
            "div",
            { staticClass: "bg-red text-white font-bold p-2 my-8 rounded" },
            [
              _vm._v("\n    We couldn't load that question\n    "),
              _c("svg-icon", {
                staticClass: "fill-current inline-block align-middle",
                attrs: { icon: "sad", size: "7" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("portal", { attrs: { to: "debug" } }, [
        _c("div", { staticClass: "debug" }, [
          _c("div", [_vm._v("Component Used:")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(_vm.question.type) +
                " => " +
                _vm._s(_vm.slideComponent) +
                ".vue"
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }