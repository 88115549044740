<template>
  <div>
    <div class="md:flex items-center mt-8">
      <div class="md:w-c5">
        <div class="w-full max-w-70">
          <base-slide-title tag="h2" class>
          
            <div>
            <transition name="fade-simple" mode="out-in">
              <div class="w-full" key="showAnswers-true" v-if="showAnswers">
                Great!
                <span class="text-theme-highlight">{{ sameVotePercentage }}%</span> of your community agree with you!
              </div>
              <div class="w-full" key="showAnswers-false" v-else>
                  <p>{{ question.text }}</p>
                  <p class="text-14 font-light max-w-60 mt-6 text-grey leading-medium">
                    Drag and drop to rearrange your answers from high to low priority.
                  </p>    
              </div>
            </transition>
            </div>
          </base-slide-title>

          
        </div>
      </div>
      <div class="my-8 md:my-0 md:w-c7 md:pl-8">
        <ChooseOrder
          @change-order="changedOrder"
          :top-label="question.meta.topLabel"
          :bottom-label="question.meta.bottomLabel"
          :disabled="locked || showAnswers"
          :fixed-order="fixedOrder"
          :options="question.options"
        />
      </div>
    </div>
    <div class="text-right mt-8">
      <SaveButton
        @submit="submit"
        :loading="$store.state.question.loading"
        :complete="showAnswers"
        :next-url="nextUrl"
      />
    </div>
    <portal to="debug">
      <div class="debug">
        <div class="font-bold">Emitted Order:</div>
        <div v-for="(question,i) in chosenOrder" :key="i">{{ question.id }} - {{ question.text }}</div>
        <div>{{ fixedOrder }}</div>
      </div>
    </portal>
  </div>
</template>

<script>
import ChooseOrder from '@/vue/components/inputs/ChooseOrder.vue'
import SaveButton from '@/vue/components/inputs/SaveButton.vue'
import { mapGetters } from 'vuex'




export default {
  components: {
    ChooseOrder,
    SaveButton,
  },

  props: {
    question: {
      type: Object,
    },
    nextUrl: {
      type: [String, Boolean],
      required: false,
    },
  },

  data() {
    return {
      chosenOrder: [],
      locked: false,
      complete: false,
    }
  },

  computed: {
    ...mapGetters('question', [
      'hasAnswered',
    ]),
    sameVotePercentage() {
      const { answers } = this.question
      if (answers) {
        const { total } = answers
        return total || false
      }
    },
    showAnswers() {
      return this.hasAnswered || this.complete
    },
    fixedOrder() {
      if (this.showAnswers) {

        if (this.chosenOrder.length) {

          const answer = []
          this.chosenOrder.forEach(({ i }) => {
            answer.push(i)
          });
          return answer

        } else {
          const { answers } = this.question
          const { user } = answers
          return user
        }
      }

      return false
    },
  },

  beforeMount() {

  },

  methods: {
    changedOrder(questions) {
      this.chosenOrder = questions
    },
    async submit() {

      this.locked = true

      if (this.chosenOrder.length <= 0) {
        // the user never rearranged anything.
        // we never had answers...so we can
        // just use the options as they appear
        // in order, eg 0,1,2,3
        this.chosenOrder = [
          ...this.question.options
        ]
      }

      const answer = []

      this.chosenOrder.forEach(({ i }) => {
        answer.push(i)
      });

      const success = await this.$store.dispatch('question/submit', {
        answer,
        qid: this.question.qid,
      })

      if (success) {
        this.complete = true
      } else {
        this.locked = false
      }

    }
  },
}
</script>

<style lang="scss">

</style>