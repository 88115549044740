/* global APP Vue MKJSON google */
import axios from "axios";

// This file exports two functions, post and get. It's just sugar
// around axios, but handles the config that Craft likes to 
// get back json from controllers.

const config = {
	responseType: 'json',
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/x-www-form-urlencoded'
	}
};

// Check live preview config settings.
const APP = window.APP || {}
const { config: APP_Config } = APP
const { isPreview, previewToken, xCraftPreview, draftId } = APP_Config;

// Scope to this file, a baseUrl,
let baseURL = '/'
// Let consumers set this baseURL
export const setBaseUrl = url => {
	baseURL = `${url}/`
}

const retrieveUserHash = () => localStorage.getItem(APP.config.poll_user_key);

// JSON.parse() to cast "false" or "true" to boolean
// https://code-maven.com/slides/javascript-programming/local-storage-boolean
export const getIsUserFinished = () => JSON.parse(localStorage.getItem(APP.config.email_submitted_key));
export const setUserFinished = () => localStorage.setItem(APP.config.email_submitted_key, true);
export const getIsDemographicsFinished = () => JSON.parse(localStorage.getItem(APP.config.demographics_submitted_key));
export const setDemographicsFinished = () => localStorage.setItem(APP.config.demographics_submitted_key, true);

export const post = async function (url, _data) {
	const params = new URLSearchParams(_data);

  params.append(APP.csrf.name, APP.csrf.value);
  params.append('user', retrieveUserHash());

	if(APP.userLocation)
  {
	  params.append('lat', APP.userLocation.lat);
	  params.append('lng', APP.userLocation.lng);
  }

	// Pass 'em if you got 'em
	if ( isPreview ) {
		params.append('x-craft-preview', xCraftPreview )
		params.append('previewToken', previewToken )
		params.append('draftId', draftId )
	}
	return axios.post( url, params, { baseURL, ...config })
};

export const get = async function (url, _data) {
	const params = {
	  user: retrieveUserHash(),
		..._data
	}

  // Pass 'em if you got 'em
	if( isPreview ) {
		params['x-craft-preview'] = xCraftPreview
		params['previewToken'] = previewToken
		params['draftId'] = draftId
	}

	return axios.get( url, {
		baseURL,
		params,
	}, config)
};


export default { get, post, setBaseUrl, getIsUserFinished, setUserFinished, getIsDemographicsFinished, setDemographicsFinished };