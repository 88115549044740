<template>
  <component
    :is="tag ? tag : 'div'"
    class="text-20 md:text-28 font-medium max-w-md leading-tight text-fg"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: ['tag'],
}
</script>

<style>
</style>