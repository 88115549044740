var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inset-0 absolute" },
    [
      _c(
        "div",
        { ref: "carousel", staticClass: "absolute inset-0 z-0" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm._t("chrome", null, { flkty: _vm.flkty })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }