<template>
  <div class="flex items-center justify-end">
    <div v-if="$slots.default" class="mr-4 font-light text-14 text-red">
      <slot />
    </div>
    <svg-icon v-if="loading" icon="tail-spin" class="mr-2 text-black" size="8" />
    <div v-if="loading" class="text-white button bg-theme-highlight">Saving...</div>

    <router-link
      class="text-white button bg-theme-highlight"
      v-else-if="nextUrl && complete"
      :to="nextUrl"
    >Next</router-link>
    <button
      v-else-if="!complete"
      @click="$emit('submit')"
      class="text-white button bg-theme-highlight"
    >Confirm</button>
    <router-link class="text-white button bg-theme-highlight" v-else-if="entryType == 'pollsDataCapture'" :to="details">Next</router-link>
    <router-link class="text-white button bg-theme-highlight" v-else-if="final" :to="final">Next</router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['loading', 'complete', 'nextUrl'],
  computed: {
    ...mapState( 'polls', [
      'entryType',
    ]),

    final() {
      return this.$store.getters['polls/finalUrl']
    },

    details() {
      return this.$store.getters['polls/detailsUrl']
    },
  }
}
</script>

<style scoped>
.button {
  width: 150px;
}
</style>
