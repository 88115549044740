var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ar-7x5" }, [
      _c("div", { staticClass: "absolute inset-0 z-0" }, [
        _c(
          "div",
          { ref: "carousel" },
          _vm._l(_vm.slides, function(ref) {
            var url = ref.url
            return _c(
              "div",
              {
                key: "slide-" + url,
                staticClass: "w-full h-full absolute",
                attrs: { "data-slide": "" }
              },
              [
                _c("img", {
                  staticClass: "w-full h-full object-cover object-center block",
                  attrs: { src: url }
                })
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "absolute bottom-0 left-0 m-8 z-1" },
        [
          _c("flickity-alt-buttons", {
            attrs: { "slot-props": { flkty: _vm.flkty } }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "p-4 text-14 font-light opacity-85" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.caption) + "\n\t")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }