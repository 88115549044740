import Vue from 'vue';
import VueRouter from 'vue-router';
import { routeBuilder } from '@/libs/route-builder';

Vue.use(VueRouter);

const routes = [];

// Globally register all page components for convenience.
// Try to keep them out of the folder if they're not needed, to avoid bloating
// the webpack build. We could refactor to lazy load and ajax the components, 
// but we're not there yet, each component _should_ be fairly lightweight and use a 
// lot of shared components.

const requireComponent = require.context(
	// Look for files in the base components directory
	'@/vue/pages',

	// Do not look in subdirectories
	true,

	// anything called .vue
	/\.vue$/
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {

	// Get the component config...
	const componentConfig = requireComponent(fileName);

	// parse the filename, and add to the routes.
	const newRoute = routeBuilder(fileName, componentConfig);
	if (newRoute) {
		routes.push(newRoute);
	}

});


// Ensure dynamic routes are at the end, so that matchers are parsed in a logical order,
// in other words /:slug should come AFTER /someroute so that /someroute is matched
// first in vue router. The order is important here!
routes.sort(({ path: a }, { path: b }) => {
	const dyn = /\/:/g

	const aMatch = a.match(dyn)
	const bMatch = b.match(dyn)

	if (aMatch && !bMatch) return 1
	if (!aMatch && b.match(dyn)) return -1

	// Ensure that multiple matches go after lesser matches.
	// it's not bulletproof but it's better.
	if (aMatch && bMatch) {
		if (aMatch.length > bMatch.length) return 1
		if (aMatch.length < bMatch.length) return -1
	}

	return 0

})


	

// It's worth logging routes in dev mode to see
// what got built. This will be standard vue-router
// config, somethign we _could_ have done manually, 
// so it's important to check this auto router builds
// a valid config:
// console.log(routes);


export const router = new VueRouter({
	mode: 'history',
	routes,

	// Give it a base...that means that all routes will be prefixed with this
	// so if we didn't have this it might be `sitename.com/some/route` but 
	// as it is, we can put ALL our vue router stuff inside a sub directory,
	// matching craft, so for example `sitename.com/polls/some/route`

	// There is a route in `config/routes.php` that is listening to polls and
	// can just direct to our simple template with a div that vue mounts onto,
	// so it's a 'contained' SPA in this instance.
	base: APP.config.routerBase
});

export default { router };