<template>
	<div>
		<div>Step 2</div>
		<div>
			Some Value: {{ controllerState.someValue }}
		</div>
		<div>
			Random Value From Server: {{ controllerState.randomValue }}
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

	
	
	computed: {
		...mapState({
			controllerState: state => state.index.controllerState
		})
	},

	methods: {
		...mapMutations({
			increment: 'increment',
		})
	}

}
</script>