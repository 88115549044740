import Vue from 'vue';

import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';

const requireComponent = require.context(
	// Look for files in the base components directory
	'@/vue/layouts',
	// Do not look in subdirectories
	false,
	// anything called layout-something.vue
	/layout-[\w-]+\.vue$/
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
	// Get the component config
	const componentConfig = requireComponent(fileName);
	// Get the PascalCase version of the component name
	const componentName = upperFirst(
		camelCase(
			fileName
			// Remove the "./_" from the beginning
				.replace(/^\.\/_/, '')
			// Remove the file extension from the end
				.replace(/\.\w+$/, '')
		)
	);
	// Globally register the component
	Vue.component(componentName, componentConfig.default || componentConfig);
});