<template>
	
		<div class="relative flex">
	
			<a
				@click="updateUrl"
				title="Share on Facebook"
				target="_BLANK"
				:href="shareFacebook"
				:class="aClass">
				<svg-icon :class="svgClass" size="5" icon="facebook"/>
				<span class="visually-hidden">Share on Facebook</span>
			</a>

			<a
				@click="updateUrl"
				title="Share on Twitter"
				target="_BLANK"
				:href="shareTwitter"
				:class="aClass">
				<svg-icon :class="svgClass" size="5" icon="twitter"/>
				<span class="visually-hidden">Share on Twitter</span>
			</a>
			
			<a
				@click="updateUrl"
				title="Share on WhatsApp"
				target="_BLANK"
				:href="shareWhatsapp"
				:class="aClass">
				<svg-icon :class="svgClass" size="5" icon="whatsapp"/>
				<span class="visually-hidden">Share on WhatsApp</span>
			</a>

			<!-- <button
				title="Copy URL to Clipboard"
				@click="copy"
				class="focus-mouse:outline-none focus:bg-white"
				:class="aClass">
				<svg-icon :class="svgClass" size="5" icon="link"/>
				<span class="visually-hidden">Copy URL to Clipboard</span>
			</button> -->
			
			<input type="hidden" v-model="shareUrl" ref="target" />
			
			<!-- Copy interface -->
			<div v-if="failed" class="mt-1">
				<label class="text-11 font-bold">Select text and copy to clipboard</label>
				<input ref="fallback" @focus="select()" type="text" 
					class="w-full mt-2 text-center focus:border-berry text-11 p-2" readonly v-model="shareUrl" />
			</div>
			<div v-else class="absolute top-100 left-0 w-full text-black text-11 font-bold font-normal mt-1 h-4">
				<template v-if="output">
					{{ output }}
				</template>
			</div>
		</div>
		
	
</template>

<script>

import IconButton from "@/vue/components/IconButton.vue";

export default {

	props: ['providedUrl'],
	components: {
		IconButton,
	},

	data() {
		
		return {
			url: window.location.href,
			output: "",
			failed: false,
			aClass: 'mx-1 inline-flex text-inherit relative hover:opacity-75 items-center justify-center group',
			svgClass: 'fill-current relative z-1',
		}	
	},

	created() {
		// this.$bus.$on('urlchange', e => {
		// 	this.url = e;
		// });
	},

	computed: {
		shareUrl() {
			
			if (this.providedUrl ) {
				return this.providedUrl
			}

			return this.url
		},
		shareTwitter() {
			return encodeURI(`https://twitter.com/intent/tweet?text=${this.shareUrl}`);
		},

		shareFacebook() {
			return encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}`);
		},
		
		shareWhatsapp() {
			return encodeURI(`https://api.whatsapp.com/send?text=${this.shareUrl}`);
		},

	},
	methods: {

		updateUrl() {
			this.url = window.location.href
		},

		select() {
			this.$refs.fallback.select();
		},

		fallback() {
			// Failed, let's show an input and let them copy...
			target.setAttribute('text')
		},
		
		copy() {

			this.updateUrl() 

			if ( this.failed ) { 
				this.fallback();
				return;
			}	

			let target = this.$refs.target;
			target.setAttribute('type', 'text');
			target.select();
			let success;

			try {
				success = document.execCommand('copy');
			} catch (err) {
				
			}

			if ( success ) {
				this.output = "Link copied to clipboard";
				setTimeout( () => {
					this.output = false;
				}, 2000 );
				/* unselect the range */
				target.setAttribute('type', 'hidden');
				window.getSelection().removeAllRanges();
			} else {
				this.output = false;
				this.failed = true;
				this.fallback();
			}

			
		}
	}

}
</script>

<style>
</style>
